export const removeMarkdownFromText = (text) => {
  if (typeof text !== 'string' || !text) {
    return ''
  }

  // Remove headers (#, ##, ###)
  text = text.replace(/#+\s+(.*)/g, '$1')

  // Remove bold (**text**)
  text = text.replace(/\*\*([^*]+)\*\*/g, '$1')

  // Remove italic (*text*)
  text = text.replace(/\*([^*]+)\*/g, '$1')

  // Remove images (![alt](src))
  text = text.replace(/!\[.*?\]\(.*?\)/g, '')

  // Remove links ([text](url))
  const re = new RegExp('\\[([^\\]]*)\\]\\([^\\)]*\\)', 'g')
  text = text.replace(re, '$1')
  //
  return text
}

export const truncate = (value, maxLength) => {
  if (!value) {
    return ''
  }

  value = value.toString()

  if (value.length > maxLength) {
    return value.substring(0, maxLength) + '...'
  }

  return value
}

export function textColorFromBg(color) {
  let txt_color = '#ffffff'
  if (color) {
    const hex = color.substring(1, 7)
    const coefs = [0.21, 0.72, 0.07]
    let l = 0
    for (let i = 0; i < 3; i++) {
      const from = i * 2
      const c = parseInt(hex.substring(from, from + 2), 16) * coefs[i]
      l += c
    }
    const lightness = l / 255
    if (lightness > 0.5) {
      txt_color = '#000'
    }
  }
  return txt_color
}
